body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111;
  color: white;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background-color: #000;
  padding: 20px 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.col {
  flex: 0 0 25%;
  padding: 0 10px;
  margin-bottom: 20px;
}

.card {
  cursor: pointer;
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.featured {
  position: relative;
  height: 70vh;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.featured-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #e50914;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 10px;
}

.video-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-player iframe {
  width: 100%;
  height: 80%;
}

.back-button {
  /* position: absolute; */
  margin-left: 20px;
  color: white;
  text-decoration: none;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: white;
}

.video-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
}

.video-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.back-button, .nav-button {
  padding: 10px 15px;
  background-color: #e50914;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover, .nav-button:hover {
  background-color: #f40612;
}

.nav-button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.video-player iframe {
  flex-grow: 1;
  border: none;
}